import axios from 'axios';
import jwtDecode from 'jwt-decode';
import FuseUtils from '../../../@fuse/FuseUtils';
import api from '../httpService/api';
import jwt from "jsonwebtoken";
import { sha256 } from 'js-sha256';
import dialog from 'sweetalert2';
//import { v4 as uuid } from 'uuid';

const jwtConfig = {
    secret: "some-secret-code-goes-here",
    expiresIn: "2 days" // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
};

class jwtService extends FuseUtils.EventEmitter {

    init() {
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(response => {
            return response;
        }, err => {
            return new Promise((resolve, reject) => {
                if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                    // if you ever get an unauthorized response, logout the user
                    this.emit('onAutoLogout', 'Invalid access_token');
                    this.setSession(null);
                }
                throw err;
            });
        });
    };

    handleAuthentication = () => {
        this.emit('onAutoLogin', true);
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            axios.post('/api/auth/register', data)
                .then(response => {
                    if (response.data.user) {
                        this.setSession(response.data.access_token);
                        resolve(response.data.user);
                    }
                    else {
                        reject(response.data.error);
                    }
                });
        });
    };

    signInWithEmailAndPassword = (email, password) => {
        return new Promise((resolve, reject) => {
            axios.get('/api/auth', {
                data: {
                    email,
                    password
                }
            }).then(response => {
                if (response.data.user) {
                    this.setSession(response.data.access_token);
                    resolve(response.data.user);
                }
                else {
                    reject(response.data.error);
                }
            });
        });
    };

    userSignIn = (email, password, locked) => { //TODO:: (email, password, locked)
        return new Promise((resolve, reject) => {
            password = sha256(password);
            var body = { email, password, locked };//TODO:: {email, password, locked}

            api.signIn(body).then(response => {
                if (response.data) {
                    if (response.data.email !== undefined) {
                        if (response.data.email !== null || response.data.password !== null) {
                            reject(response.data);
                        }
                    }
                    resolve(response.data);
                }
                else {
                    reject(response.data);
                }
            }).catch(error => {
                //Check {error.response} for the error message returned. If the error is not here, please look at response.error in then section.
                dialog.fire({
                    title: 'An Error Occured.',
                    text: 'An Error occured while signing in.',
                    icon: 'error',
                    confirmButtonText: 'Close',
                    timer: 4000
                })
            });
        });
    };

    userSignInWithToken = () => {
        return new Promise((resolve, reject) => {

            api.userAuthID().then(response => {
                if (response.data) {
                    resolve(response.data);
                }
                else {
                    this.emit('onAutoLogout');
                }
            })
                .catch(error => {
                    this.emit('onAutoLogout');
                });
        });
    };

    logout = () => {
        api.logout().then();
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            axios.get('/api/auth/access-token', {
                data: {
                    access_token: this.getAccessToken()
                }
            })
                .then(response => {
                    if (response.data.user) {
                        this.setSession(response.data.access_token);
                        resolve(response.data.user);
                    }
                    else {
                        this.logout();
                        reject('Failed to login with token.');
                    }
                })
                .catch(error => {
                    this.logout();
                    reject('Failed to login with token.');
                });
        });
    };

    updateUserData = (user) => {
        return axios.post('/api/auth/user/update', {
            user: user
        });
    };

    setSession = access_token => {
        if (access_token) {
            localStorage.setItem('jwt_access_token', access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        }
        else {
            localStorage.removeItem('jwt_access_token');
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    getAccessToken = () => {
        return window.localStorage.getItem('jwt_token');
    };
}

const instance = new jwtService();

export default instance;
