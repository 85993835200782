import React, { useState, useEffect, Fragment } from 'react';
import { Drawer, Typography, AppBar, Toolbar, Box} from '@material-ui/core';
import { FuseScrollbars } from '../../../../@fuse';
import {useSelector, useDispatch} from 'react-redux';
import * as Actions from './store/actions/index'
import withReducer from '../../../../app/store/withReducer';
import reducer from './store/reducers';
import { ThemeProvider, makeStyles, withStyles } from '@material-ui/styles';
//import Toolbar from '@mui/material/Toolbar';
//import AppBar from '@mui/material/AppBar';
import NotificationCard from './QuickPanelNotificationCard';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Api from '../../../services/httpService/api';

const useStyles = makeStyles(theme => ({
    root: {
        width: 280,
        backgroundColor: "#ebebeb"
    },
    appBar: {
        background: theme.palette.secondary
    },
}));

function QuickPanel(props)
{
    const dispatch = useDispatch();
    const state = useSelector(({quickPanel}) => quickPanel.state);
    const classes = useStyles();
    const [notifications, setNotifcations] = useState(null);

    //useEffect(() => {
    //    Api.getNotifications().then((resp) => {
    //        if (resp.data) {
    //            setNotifcations(resp.data);
    //        }
    //    });
    //}, []);

    return (
        <Drawer
            classes={{paper: classes.root}}
            open={state}
            anchor="right"
            onClose={ev => dispatch(Actions.toggleQuickPanel())}
        >
            <FuseScrollbars>
                <div className="w-full">
                    <AppBar position="static" color="primary">
                        <Toolbar
                            sx={{
                                width: "100%",
                                maxWidth: 600,
                                mx: "auto",
                            }}
                        >
                            <Typography alignContent="center" variant="h6" className="w-full">
                               Notifcations
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    {notifications != null &&
                        <div>
                        {notifications.map((item) => (
                                <NotificationCard
                                    key={item.workOrderId}
                                    className="mt-2"
                                    item={item}
                                />
                            ))}
                        </div>
                    }
                </div>
                             
            </FuseScrollbars>
        </Drawer>
    );
}

export default withReducer('quickPanel', reducer)(QuickPanel);
