import React from 'react';
import { Redirect } from 'react-router-dom';

export const UserConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/settings/users/',
            component: React.lazy(() => import('./UserApp'))
        }
    ]
};