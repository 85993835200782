import React from "react";
import { Button } from "@material-ui/core";
import api from "../../../services/httpService/api";

function SSOLogin(props) {
  function redirect() {
    api.samlSignIn().then((response) => (window.location.href = response.data));
  }

  return (
    <div className="w-full">
      <Button
        className="w-full my-48"
        color="primary"
        variant="contained"
        onClick={redirect}
      >
        Log In
      </Button>
    </div>
  );
}

export default SSOLogin;
