import React, { Component } from "react";
import axios from "axios";
import { darken } from '@material-ui/core/styles/colorManipulator';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import api from '../../../services/httpService/api';

const useStyles = makeStyles(theme => ({
    root: {
        background: 'radial-gradient(' + darken(theme.palette.primary.dark, 0.5) + ' 0%, ' + theme.palette.primary.dark + ' 80%)',
        color: '#ffffff'
    }
}));

async function login() {
    const request = api.executeSSO();

    request.then((response) => window.location.href = response.data);
}

function RedirectText() {
    const classes = useStyles();
    return(
        <div className={clsx(classes.root, "flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32")}>

            <div className="flex flex-col items-center justify-center w-full">
                <Typography variant="h2" className="mt-16 font-bold">
                    Redirecting...
                  </Typography>
            </div>

        </div>
    );
}

export class Redirect extends Component {
  constructor( props ){
    super();
    this.state = { ...props };
    }
  
    componentWillMount() {
        login();
  }
  render(){
    return(<RedirectText/>);
  }
}

export default Redirect;