import React, { useEffect, useState } from 'react';
import { FuseNavigation } from '../../../@fuse';
import clsx from 'clsx';
import {useSelector} from 'react-redux';

function Navigation(props)
{
    const navigation = useSelector(({ fuse }) => fuse.navigation.list);
    const user = useSelector(({ auth }) => auth.user);
    const [filteredNavigation, setFilteredNavigation] = useState(navigation);

    useEffect(() => {
        setFilteredNavigation(navigation);

    }, [user, navigation]);

    return (
        <FuseNavigation className={clsx("navigation", props.className)} navigation={filteredNavigation} layout={props.layout} dense={props.dense} active={props.active}/>
    );
}

Navigation.defaultProps = {
    layout: "vertical"
};

export default Navigation;
