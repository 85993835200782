import React from "react";

export const WorkflowsConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: false 
                }
            }
        }
    },
    routes: [
        {
            path: "/settings/workflows",
            component: React.lazy(() => import("./Workflows"))
        },
       
    ]
};