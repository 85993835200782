import axios from "axios";

const Api = axios.create();

Api.interceptors.response.use((response) => { return response }, (error) => {
    if (error) {
        console.error('error', error.response);
        if (error.response) {
            if (error.response.status === 401) {
                if (window.location.pathname !== '/login') {
                    window.location.href = '/login';
                }
            }
        }
    }
    return error;
});

export default Api;