import { ComingSoonPageConfig } from './pages/ComingSoon/ComingSoonPageConfig';
import { Error404PageConfig } from './pages/NotFound/Error404PageConfig';
import { AnalyticsConfig } from './Analytics/AnalyticsConfig';
import { RedirectConfig } from './Redirect/RedirectConfig';
import { SamlConsumeConfig } from './SamlConsume/SamlConsumeConfig';
import { dashboardsAppConfig } from "./IoT/MainDashboard/dashboards/dashboardsAppConfig";
import { AssetsConfig } from "./Assets/AssetsConfig";
import { SensorConfig } from "./IoT/Sensors/SensorsConfig";
import { NetworksConfig } from "./IoT/Networks/NetworksConfig";
import { GatewaysConfig } from "./IoT/Gateways/GatewaysConfig";
import { PersonnelAppConfig } from "./Personnel/PersonnelAppConfig";
import { UserConfig } from "./User/UserConfig";
import { UserRolesConfig } from "./UserRoles/UserRolesConfig";
import { ProfilePageConfig } from "./pages/profile/ProfilePageConfig";
import { HomeConfig } from "../Home/homeConfig";
import { ChatAppConfig } from "./chat/ChatAppConfig";
import { CrewsConfig } from "./crews/CrewsConfig";
import { MaintenancePlansConfig } from "./MaintenancePlans/MaintenancePlansConfig";
import { MetersConfig } from "./Meters/MetersConfig";
import { WorkRequestsConfig } from "./WorkRequest/WorkRequestConfig";
import { WorkOrderConfig } from "./WorkOrder/WorkOrderConfig";
import { ClassificationsConfig } from './classificationSchems/ClassificationsConfig';
import { MasterPartConfig } from './Master Parts/MasterPartConfig';
import { TaskGroupsConfig } from './TaskGroups/TaskGroupsConfig';
import {LocationsConfig} from "./Locations/LocationsConfig";
import { AllAssetsConfig } from "./AllAssets/AllAssetsConfig";
import { WarehouseConfig } from "./Warehouses/WarehouseConfig";
import { CalendarConfig } from './calendar/CalendarConfig';
import { SuppliersConfig } from './Suppliers/SuppliersConfig';
import { WorkflowsConfig } from './Workflows/WorkflowsConfig';

export const appsConfigs = [
    AnalyticsConfig,
    AssetsConfig,
    ComingSoonPageConfig,
    CrewsConfig,
    dashboardsAppConfig,
    Error404PageConfig,
    GatewaysConfig,
    HomeConfig,
    NetworksConfig,
    PersonnelAppConfig,
    ProfilePageConfig,
    RedirectConfig,
    SamlConsumeConfig,
    SensorConfig,
    UserConfig,
    UserRolesConfig,
    ProfilePageConfig,
    HomeConfig,
    ChatAppConfig,
    MaintenancePlansConfig,
    MetersConfig,
    WorkRequestsConfig,
    ClassificationsConfig,
    WorkOrderConfig,
    MasterPartConfig,
    TaskGroupsConfig,
    LocationsConfig,
    AllAssetsConfig,
    WarehouseConfig,
    CalendarConfig,
    SuppliersConfig,
    WorkflowsConfig
];