import { FuseUtils } from '../../../../@fuse';
import api from '../../../services/httpService/api';

export const GET_NAVIGATION = '[NAVIGATION] GET NAVIGATION';
export const SET_NAVIGATION = '[NAVIGATION] SET NAVIGATION';
export const FILTER_NAVIGATION = '[NAVIGATION] FILTER NAVIGATION';
export const RESET_NAVIGATION = '[NAVIGATION] RESET NAVIGATION';
export const GET_NAVITEM = '[NAVIGATION] GET NAVITEM';

export function getNavigation()
{
    return {
        type: GET_NAVIGATION
    }
}

export function setNavigation(navigation)
{
    return {
        type: SET_NAVIGATION,
        navigation
    }
}

export function getNavigationPageItem(module, submodule) {
    return {
        type: GET_NAVITEM,
        module,
        submodule
    }
}

export function filterNavigation(userRoleId, profile) {
    const request = api.getTenantNav(userRoleId, profile);

    return (dispatch) =>
        request.then((response) => {
            const navigation = response.data;
            dispatch({
                type: FILTER_NAVIGATION,
                navigation
            })
        });
}

export function resetNavigation()
{
    return {
        type: RESET_NAVIGATION
    }
}

export function appendNavigationItem(item, parentId)
{
    return (dispatch, getState) => {
        const {navigation} = getState().fuse;
        return dispatch({
            type      : SET_NAVIGATION,
            navigation: FuseUtils.appendNavItem(navigation, item, parentId)
        });
    }
}

export function prependNavigationItem(item, parentId)
{
    return (dispatch, getState) => {
        const {navigation} = getState().fuse;
        return dispatch({
            type      : SET_NAVIGATION,
            navigation: FuseUtils.prependNavItem(navigation, item, parentId)
        });
    }
}

export function updateNavigationItem(id, item)
{
    return (dispatch, getState) => {
        const {navigation} = getState().fuse;
        return dispatch({
            type      : SET_NAVIGATION,
            navigation: FuseUtils.updateNavItem(navigation, id, item)
        });
    }
}

export function removeNavigationItem(id)
{
    return (dispatch, getState) => {
        const {navigation} = getState().fuse;
        return dispatch({
            type      : SET_NAVIGATION,
            navigation: FuseUtils.removeNavItem(navigation, id)
        });
    }
}
