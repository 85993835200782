import axios from 'axios';
import api from '../../../../../services/httpService/api';

export const GET_CONTACTS = '[CHAT PANEL] GET CONTACTS';
export const GET_FRIENDLIST = '[CHAT PANEL] GET FRIENDLIST';
export const SET_SELECTED_CONTACT_ID = '[CHAT PANEL] SET SELECTED CONTACT ID';
export const REMOVE_SELECTED_CONTACT_ID = '[CHAT PANEL] REMOVE SELECTED CONTACT ID';

export function getContacts(id)
{
    return (dispatch) => {
    if(!id){return;}
    api.getNewChatsContactList(id).then((response) =>
            dispatch({
                type   : GET_CONTACTS,
                payload: response.data
            })
        );
    }
}

export function getFriendlist(id)
{
    return (dispatch) => {
    if(!id){return;}
    api.getNewChatsFriendList(id).then((response) =>
            dispatch({
                type   : GET_FRIENDLIST,
                payload: response.data
            })
        );
    }
}

export function setselectedContactId(contactId)
{
    return {
        type   : SET_SELECTED_CONTACT_ID,
        payload: contactId
    }
}

export function removeSelectedContactId()
{
    return {
        type: REMOVE_SELECTED_CONTACT_ID
    }
}
