import React from "react";
import { Redirect } from "react-router-dom";

export const WorkOrderConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
  routes: [
    {
          path: "/maintenance-management/work-order-board/:boardId",
          component: React.lazy(() => import("./board/Board"))
    },
    {
        path: "/maintenance-management/work-order-board/",
      component: React.lazy(() => import("./boards/Boards"))
    },
    {
        path: "/maintenance-management/work-order-board",
        component: () => <Redirect to="/asset-management/work-order-board" />
    }
  ]
};