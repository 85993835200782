import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "../../@fuse";
import { appsConfigs } from "../main/apps/appsConfigs";
import { LoginConfig } from "../main/login/LoginConfig";

const routeConfigs = [
  ...appsConfigs,
  LoginConfig
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ["admin"]),
  {
    path: "/",
    exact: true,
      component: () => <Redirect to="/home" />
   },
  {
    component: () => <Redirect to="/pages/errors/error-404" />
  }
];

export default routes;