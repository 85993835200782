import React from 'react';
import {Icon, IconButton} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

function HelpButton()
{
    const helpUrl = useSelector(({ fuse }) => fuse.tenant.tenantDetails.helpUrl);
    const dispatch = useDispatch();

    function handleClick(e) {
        e.preventDefault();
        window.open(helpUrl);
    }

    return (
        <IconButton className="w-64 h-64" onClick={handleClick}>
            <Icon>helpoutline</Icon>
        </IconButton>
    );
}

export default HelpButton;