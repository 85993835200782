import React from "react";

export const MetersConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: false 
                }
            }
        }
    },
    routes: [
        {
            path: "/asset-management/meters/:productId",
            component: React.lazy(() => import("./MeterRecord"))
        },
        {
            path: "/asset-management/meters",
            component: React.lazy(() => import("./Meters"))
        },
       
    ]
};