import api from '../../../services/httpService/api';
import logo from "../../../main/Icons/logo.png";
import Background from '../../../main/Icons/Login_Background.png';
export const FETCH_TENANT = '[TENANT] FETCH TENANT';
export const FETCH_TENANT_LOGO = '[TENANT] FETCH TENANT LOGO';
export const FETCH_TENANT_LOGIN_BACKGROUND = '[TENANT] FETCH TENANT LOGIN BACKGROUND';

export function fetchTenant(options) {
    const request = api.getTenant();

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: FETCH_TENANT,
                tenant: response.data
            })
        );
}

export function fetchLogo() {
    const request = api.getLogo();

    return dispatch =>
        request.then(
            response => {
                if (response.data.size > 0) {
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = function (e) {
                        dispatch({
                            type: FETCH_TENANT_LOGO,
                            logo: e.target.result
                        });
                    }
                }
                else {
                    dispatch({
                        type: FETCH_TENANT_LOGO,
                        logo: logo
                    });
                }
            });

}

export function fetchLoginBackground() {
    const request = api.getLoginBackground();

    return dispatch =>
        request.then(
            response => {
                if (response.data.size > 0) {
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = function (e) {
                        dispatch({
                            type: FETCH_TENANT_LOGIN_BACKGROUND,
                            loginBackground: e.target.result
                        });
                    }
                }
                else {
                    dispatch({
                        type: FETCH_TENANT_LOGIN_BACKGROUND,
                        loginBackground: Background
                    });
                }
            });

}