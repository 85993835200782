import React from "react";

export const NetworksConfig = {
  settings: {
    layout: {
            config: {
                rightSidePanel: {
                    display: false
                }
            }
        }
  },
    routes: [
        {
          path: "/IoT/Networks",
          component: React.lazy(() => import("./Networks"))
        },
    
  ]
};
