import React, { useRef, useState, useEffect } from 'react';
import { Button, Avatar, Divider, Tooltip } from '@material-ui/core';
import { FuseScrollbars, FuseAnimateGroup } from '../../../../@fuse';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from './store/actions';
import { makeStyles } from '@material-ui/styles';
//import PubNub from "pubnub";
import api from '../../../services/httpService/api';
import { ChatClient } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default
    },
    contactButton: {
        width: 70,
        minWidth: 70,
        flex: '0 0 auto',
        '&.active:after': {
            position: 'absolute',
            top: 8,
            right: 0,
            bottom: 8,
            content: "''",
            width: 4,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor: theme.palette.primary.main
        }
    },
    unreadBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 10,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    status: {
        position: 'absolute',
        width: 12,
        height: 12,
        bottom: 4,
        left: 44,
        border: '2px solid ' + theme.palette.background.default,
        borderRadius: '50%',
        zIndex: 10,

        '&.online': {
            backgroundColor: '#4CAF50'
        },

        '&.do-not-disturb': {
            backgroundColor: '#F44336'
        },

        '&.away': {
            backgroundColor: '#FFC107'
        },

        '&.offline': {
            backgroundColor: '#646464'
        }
    }
}));

function ContactList(props) {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);
    const contacts = useSelector(({ chatPanel }) => chatPanel.contacts.entities);
    const friendList = useSelector(({ chatPanel }) => chatPanel.contacts.friendList);
    const selectedContact = useSelector(({ chatPanel }) => chatPanel.contacts.selectedContactId);
    const [onlineToken, setOnlineToken] = useState(null);

    const classes = useStyles();
    const contactListScroll = useRef(null);

    useEffect(() => {
      if(friendList && friendList[0] && !onlineToken){
        const friend = friendList[0];
        const date = new Date();

        if(friend.senderExpiry && (new Date(friend.senderExpiry)) > date ){
            setOnlineToken(friend.senderToken);
            const chatClient = new ChatClient("https://iox-uat-chat.communication.azure.com", new AzureCommunicationTokenCredential(friend.senderToken)); 
            chatClient.startRealtimeNotifications();
            chatClient.on('chatMessageReceived', () => {
                // used to set delay for the db to update the chat thread
                setTimeout(() => {
                    dispatch(Actions.getFriendlist(user.uuid));  
                },2000)
            }); 
            chatClient.on('realTimeNotificationConnected', () => {
                api.updateNewChatStatus({
                    id: user.uuid,
                    status: true,
                })
              });
              // subscribe to realTimeNotificationDisconnected event
              chatClient.on('realTimeNotificationDisconnected', () => {
                api.updateNewChatStatus({
                    id: user.uuid,
                    status: false,
                })
              }); 
       }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[friendList, onlineToken]);


    const handleContactClick = React.useCallback((contact) => {
        dispatch(Actions.openChatPanel());
        // dispatch(Actions.getChat(contact));
        scrollToTop();
        const foundThread = (friendList || []).filter(e => e.receiverId === contact.receiverId);
        if(foundThread.length > 0){
         dispatch(Actions.setselectedContactId(foundThread[0]));
         const contact = foundThread[0];
         if((contact.chatId && (contact.readId === contact.senderId))){
         api.updateNewChatThread({
             id: null, 
             chatId: contact.chatId,
         }).then(() => dispatch(Actions.getFriendlist(user.uuid)))
         }

        }else {
            dispatch(Actions.setselectedContactId({
                new: contact.receiverId, 
                receiverId: contact.receiverId,
                senderId: user.uuid,
                readId: null,
            }));
        }
        dispatch(Actions.setChatData([]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[contacts, friendList, user.uuid]);

    const scrollToTop = () => {
        contactListScroll.current.scrollTop = 0;
    };
     
    function listFilter(users, friends) {
        return users.filter(function(array_el){
            return friends.filter(function(anotherOne_el){
               return anotherOne_el.receiverId === array_el.receiverId;
            }).length === 0;
         });
    }

    const ContactButton = ({ contact }) => {


        return (
            <Tooltip title={contact.fullName} placement="left">
                <Button
                    onClick={() => handleContactClick(contact)}
                    className={clsx(classes.contactButton, { 'active': ((contact.chatId && selectedContact.receiverId === contact.receiverId)) || (selectedContact.new === contact.receiverId) })}
                >
                    
                    {((contact.chatId && (contact.readId === contact.senderId))) ? (
                        <div className={classes.unreadBadge}>{''}</div>
                    ) : ''}
                    <div className={clsx(contact.online ?  'online' : 'offline', classes.status)} />
                    <Avatar
                        src={contact.avatar || "https://ui-avatars.com/api/?background=random&name="+contact.fullName}
                        alt={contact.fullName}
                    >
                        {!contact.avatar || contact.avatar === '' ? contact.fullName : ''}
                    </Avatar>
                </Button>
            </Tooltip>
        )
    };

    return (
        <FuseScrollbars
            className={clsx(classes.root, "flex flex-shrink-0 flex-col overflow-y-auto py-8")}
            ref={contactListScroll}
        >
            {(contacts.length > 0 || friendList.length > 0 ) && (
                <React.Fragment>
                    <FuseAnimateGroup
                        enter={{
                            animation: "transition.expandIn"
                        }}
                        className="flex flex-col flex-shrink-0"
                    >
                        {friendList.map(chat => {
                             return (
                                    <ContactButton key={chat.chatId} contact={chat} />
                                )
                            })}
                        <Divider className="mx-24 my-8" />
                        {listFilter(contacts, friendList).map(contact => {
                                 return (
                                     <ContactButton key={contact.receiverId} contact={contact} /> 
                        )
                        })}
                    </FuseAnimateGroup>
                </React.Fragment>
            )}
        </FuseScrollbars>
    );
}

export default ContactList;

