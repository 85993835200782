import React from 'react';
import { Icon, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import SpinviewLogo from '../Icons/spinview-logo-temp.png';


function HelpButton() {
    const dispatch = useDispatch();

    function handleClick(e) {
        e.preventDefault();
        window.open(" https://property.spinview.io/#/login");
    }

    return (
        <IconButton className="w-64 h-64" onClick={handleClick}>
            <img
                className="w-32"
                src={SpinviewLogo}
                alt="Spinview"
            />
        </IconButton>
    );
}

export default HelpButton;

