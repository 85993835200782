import axios from 'axios';
import moment from 'moment/moment';
import {setselectedContactId} from './contacts.actions';
import { closeMobileChatsSidebar } from '../../../../../main/apps/chat/store/actions/sidebars.actions';
import api from '../../../../../services/httpService/api';

export const GET_CHAT = '[CHAT PANEL] GET CHAT';
export const REMOVE_CHAT = '[CHAT PANEL] REMOVE CHAT';
export const SEND_MESSAGE = '[CHAT PANEL] SEND MESSAGE';
export const GET_CHATS = '[CHAT PANEL] GET CHATS';
export const GET_OPENCHATS = "[CHAT APP] GET OPENCHATS";
export const SET_CHAT_DATA = "[CHAT APP] SET CHAT DATA";

export function getChat(contact) {
    return (dispatch, getState) => {
         
        //Current Logged in User 
        const { userId } = getState().auth.user.data.info; 

        const datenow = moment().format();   

        const params = {
            "ChatKey": "KeyU" + userId + "U" + contact.tenantUserId,
            "ChatUser1": userId,
            "ChatUser2": contact.tenantUserId,
             "NotificationTimestamp": datenow
        }
        const request = api.getChat(params);
        return request.then(response => {
            response.data.firstName = contact.firstName;
            response.data.fullName = contact.fullName;
            dispatch(setselectedContactId(contact.tenantUserId));
            dispatch(closeMobileChatsSidebar());
            dispatch(setTime(response.data, params.NotificationTimestamp, contact.tenantUserId));
            dispatch(getOpenChats(userId));

            return dispatch({
                type: GET_CHAT,
                chat: response.data,
                userChatData: response.data
            });
        });
    };
}

export function setTime(response, time, tenantUuid) {

    var message = {};

    //user kaylene -- tenantid 6 user2 is 6 (Davis); writes to notificationTimestamp
    //if the user loging in is user1 save to 1


    if (tenantUuid === response.chatUser2) {
        message = {
            "ChatId": response.chatId,
            "ChatKey": response.chatKey,
            "ChatUser1": response.chatUser1,
            "ChatUser2": response.chatUser2,
            "NotificationTimestamp": time,
            "Notification2Timestamp": response.notification2Timestamp,
        };
    }
    //user david -- TenantId 4 user1 is 4 (kaylene): writes to Notification2Timestamp
    //if the user loging in is user2 save to 2 
    else if (tenantUuid === response.chatUser1) {
        message = {
            "ChatId": response.chatId,
            "ChatKey": response.chatKey,
            "ChatUser1": response.chatUser1,
            "ChatUser2": response.chatUser2,
            "NotificationTimestamp": response.notificationTimestamp,
            "Notification2Timestamp": time,

        };
    }

    const request = api.putChat(message.ChatId, message);

    return dispatch =>
        request.then(response => {
            return dispatch({
                type: GET_OPENCHATS,
                chat: response.data,
                openchats: response.data,
            });
        });

}

export function setChatData(data) {
    return (dispatch) =>
            dispatch({
                type: SET_CHAT_DATA,
                payload: data || []
            })
}

export function getOpenChats(id) {
    //const request = axios.get('/api/chat/contacts');
    const request = api.GetOpenChats(id);
    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_CHATS,
                payload: response.data
            })
        );
}

//export function getChat(contactId)
//{
//    return (dispatch, getState) => {
//        console.log('getstate', getState());
//        const {id: userId} = getState().chatPanel.user;
//        const request = axios.get('/api/chat/get-chat', {
//            params: {
//                contactId,
//                userId
//            }
//        });

//        return request.then((response) => {

//            dispatch(setselectedContactId(contactId));

//            dispatch(closeMobileChatsSidebar());

//            return dispatch({
//                type        : GET_CHAT,
//                chat        : response.data.chat,
//                userChatData: response.data.userChatData
//            });
//        });
//    }
//}

export function removeChat()
{
    return {
        type: REMOVE_CHAT
    };
}

export function sendMessage(messageText, chatId, userId)
{
    const message = {
        'who'    : userId,
        'message': messageText,
        'time'   : new Date()
    };

    const request = axios.post('/api/chat/send-message', {
        chatId,
        message
    });

    return (dispatch) =>
        request.then((response) => {
                return dispatch({
                    type        : SEND_MESSAGE,
                    message     : response.data.message,
                    userChatData: response.data.userChatData
                })
            }
        );
}
