import React from 'react';
import { Redirect } from 'react-router-dom';

export const dashboardsAppConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/iot/dashboards/boards/:boardId/:boardUri?',
            component: React.lazy(() => import('./board/Board'))
        },
        {
            path: '/iot/dashboards/boards',
            component: React.lazy(() => import('./boards/Boards'))
        },
        {
            path: '/iot/dashboards',
            component: () => <Redirect to="/iot/dashboards/boards" />
        }
    ]
};