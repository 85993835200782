import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { NavLinkAdapter } from '../../../../@fuse';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';

function NotificationCard(props)
{
    const { item, className } = props;
    const variant = item?.variant || '';
    //console.log("props", props);

    return (
        <div className="py-6 px-8 ">
            <Card
                className={clsx(
                    'flex items-center relative w-full rounded-xl p-12 min-h-64 shadow space-x-8',
                    variant === 'success' && 'bg-green-600 text-white',
                    variant === 'info' && 'bg-blue-700 text-white',
                    variant === 'error' && 'bg-red-600 text-white',
                    variant === 'warning' && 'bg-orange-600 text-white',
                    className
                )}
                style={{borderRadius: "15px"}}
                elevation={2}
                //component={item.useRouter ? NavLinkAdapter : 'div'}
                //to={item.link || ''}
                //role={item.link && 'button'}
            >
                {item.icon && !item.image && (
                    <Box
                        sx={{ backgroundColor: 'background.default' }}
                        className="flex shrink-0 items-center justify-center w-32 h-32 mr-12 rounded-full"
                    >                      
                        <Icon>{item.icon}</Icon>
                    </Box>
                )}

                
                <div className="flex flex-col flex-auto">
                    {item.workOrderCode ? <Typography fontWeight={"bold"} fontSize={"1.2rem"} className="font-semibold line-clamp-1">{item.workOrderCode}</Typography> : <Typography fontWeight={"bold"} fontSize={"1.2rem"} className="font-semibold line-clamp-1">{item.workRequestId}</Typography> }

                    {item.workOrderDescription ? (
                        <div className="line-clamp-2" dangerouslySetInnerHTML={{ __html: item.workOrderDescription }} />
                    ) : (<div className="line-clamp-2" dangerouslySetInnerHTML={{ __html: item.workRequest }} />)}

                    {item.dateCreated && (
                        <Typography className="mt-8 text-sm leading-none " color="text.secondary">
                            {item.dateCreated}
                        </Typography>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default NotificationCard;