import * as Actions from '../../actions/fuse';

const initialState = {
    tenantDetails: {
        name: '',
        identifier: ''
    },
    logo: null,
    loginBackground: null
};

const dialog = function (state = initialState, action) {
    switch (action.type) {
        case Actions.FETCH_TENANT:
            {
                return {
                    ...state,
                    tenantDetails: action.tenant
                };
            }
        case Actions.FETCH_TENANT_LOGO:
            {
                return {
                    ...state,
                    logo: action.logo
                };
            }
        case Actions.FETCH_TENANT_LOGIN_BACKGROUND:
            {
                return {
                    ...state,
                    loginBackground: action.loginBackground
                };
            }
        default:
            {
                return state;
            }
    }
};

export default dialog;
