import React from 'react';
import {Redirect} from 'react-router-dom';

export const PersonnelAppConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes  : [
        {
            path: '/settings/people/:id',
            component: React.lazy(() => import('./PersonnelApp'))
        },
        {
            path: '/settings/people',
            component: () => <Redirect to="/settings/people/all"/>
        }
    ]
};