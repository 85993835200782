import * as Actions from '../actions';

const initialState = {
    chats: [],
    chatData: [],
};

const chat = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_CHAT:
        {
            return {
                ...action.chat
            };
        }
        case Actions.SET_CHAT_DATA:
            {
                return {
                    ...state,
                    chatData: action.payload,
                };
            }
        case Actions.REMOVE_CHAT:
        {
            return null;
            }
        case Actions.GET_CHATS:
            {
                return {
                    ...state,
                    chats: [...action.payload]
                };
            }
        case Actions.SEND_MESSAGE:
        {
            return {
                ...state,
                dialog: [
                    ...state.dialog,
                    action.message
                ]
            };
        }
        default:
        {
            return state;
        }
    }
};

export default chat;