import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Paper, Typography, TextField, IconButton, Icon } from '@material-ui/core';
import { FuseScrollbars } from '../../../../@fuse';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment/moment';
import * as Actions from './store/actions';
import { makeStyles } from '@material-ui/styles';
import api from '../../../services/httpService/api';
import { ChatClient } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

//import PubNub from "pubnub";

const useStyles = makeStyles(theme => ({
    messageRow: {
        '&.contact': {
            '& .bubble': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                '& .time': {
                    marginLeft: 12
                }
            },
            '&.first-of-group': {
                '& .bubble': {
                    borderTopLeftRadius: 20
                }
            },
            '&.last-of-group': {
                '& .bubble': {
                    borderBottomLeftRadius: 20
                }
            }
        },
        '&.me': {
            paddingLeft: 40,

            '& .avatar': {
                order: 2,
                margin: '0 0 0 16px'
            },
            '& .bubble': {
                marginLeft: 'auto',
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.getContrastText(theme.palette.grey[300]),
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                '& .time': {
                    justifyContent: 'flex-end',
                    right: 0,
                    marginRight: 12
                }
            },
            '&.first-of-group': {
                '& .bubble': {
                    borderTopRightRadius: 20
                }
            },

            '&.last-of-group': {
                '& .bubble': {
                    borderBottomRightRadius: 20
                }
            }
        },
        '&.contact + .me, &.me + .contact': {
            paddingTop: 20,
            marginTop: 20
        },
        '&.first-of-group': {
            '& .bubble': {
                borderTopLeftRadius: 20,
                paddingTop: 13
            }
        },
        '&.last-of-group': {
            '& .bubble': {
                borderBottomLeftRadius: 20,
                paddingBottom: 13,
                '& .time': {
                    display: 'flex'
                }
            }
        }
    }
}));

function Chat(props) {
    const dispatch = useDispatch();
    const contacts = useSelector(({ chatPanel }) => chatPanel.contacts.entities);
    const selectedContactId = useSelector(({ chatPanel }) => chatPanel.contacts.selectedContactId);
    const chat = useSelector(({ chatPanel }) => chatPanel.chat);
    const listChats = useSelector(({ chatPanel }) => chatPanel.chat.chatData);
    const user = useSelector(({ chatPanel }) => chatPanel.user);
    const users = useSelector(({ auth }) => auth.user);
    const chatRef = useRef(null);
    const classes = useStyles();
    const chatScroll = useRef(null);
    const [messageText, setMessageText] = useState('');
    const [newClient, setClient] = useState(null);
    const [newThread, setThread] = useState(null);
    const [selectedThread, setSelectedThread] = useState(null);



    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'auto'
        /* you can also use 'auto' behaviour 
           in place of 'smooth' */
    });
    const RefreshClient = React.useCallback(async () => {
        if(!newClient && selectedContactId != null){
            const chatClient = new ChatClient("https://iox-uat-chat.communication.azure.com", new AzureCommunicationTokenCredential(selectedContactId.senderToken));
            setClient(chatClient);
            let chatThreadClient = chatClient.getChatThreadClient(selectedContactId.threadId);
            setSelectedThread(selectedContactId.threadId);
            setThread(chatThreadClient);
            const chats =  chatThreadClient.listMessages();
            const listMessages = [];
            for await (const message of  chats) {
                if(message.type === "text"){
                    const msg = {
                        id : message.sender.communicationUserId,
                        message: message.content.message,
                        date : message.createdOn,
                        mId: message.id
                    }
                    listMessages.push(msg); 
                }
             }
            dispatch(Actions.setChatData(listMessages.reverse()));
            // open notifications channel
            await chatClient.startRealtimeNotifications();
            chatClient.on("chatMessageReceived", (e) => {
                if(e.type === "Text" && e.sender.communicationUserId !== selectedContactId.senderTokenId)
                RefreshClient();
            });

        }else if(newClient && selectedContactId != null && selectedThread === selectedContactId.threadId){
            const chats =  newThread.listMessages();
            const listMessages = [];
            for await (const message of  chats) {
                if(message.type === "text"){
                    const msg = {
                        id : message.sender.communicationUserId,
                        message: message.content.message,
                        date : message.createdOn,
                        mId: message.id
                    }
                    listMessages.push(msg); 
                }
            }
            dispatch(Actions.setChatData(listMessages.reverse()));

        }else if(newClient && selectedContactId != null && selectedThread !== selectedContactId.threadId){
            // await newClient.stopRealtimeNotifications();
            // newClient.off();
            let chatThreadClient = newClient.getChatThreadClient(selectedContactId.threadId);
            setSelectedThread(selectedContactId.threadId);
            setThread(chatThreadClient); 
            const chats =  chatThreadClient.listMessages();
            const listMessages = [];
            for await (const message of  chats) {
                if(message.type === "text"){
                    const msg = {
                        id : message.sender.communicationUserId,
                        message: message.content.message,
                        date : message.createdOn,
                        mId: message.id
                    }
                    listMessages.push(msg); 
                }
             }
            dispatch(Actions.setChatData(listMessages.reverse()));
        }
    },[dispatch, newClient, newThread, selectedContactId, selectedThread]);

    useEffect(() => {
        if (chat) {
            scrollToBottom();
        }
    }, [ chat]);

    useEffect(() => {
        if(selectedContactId.chatId){
            RefreshClient();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContactId.chatId]);

    function scrollToBottom() {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }

    function shouldShowContactAvatar(item, i) {
        return (
            item.uuid === selectedContactId &&
            ((chat[i + 1] && chat.dialog[i + 1].uuid !== selectedContactId) || !chat[i + 1])
        );
    }

    function onInputChange(ev) {
        setMessageText(ev.target.value);
    }

    
    const onMessageSubmit = React.useCallback(async (ev) => {
        ev.preventDefault();
        if (messageText === '') {
            return;
        }
        if(selectedContactId.new){
            dispatch(Actions.setChatData([{
                id : 'new',
                message: messageText,
                date : new Date(),
                mId: 1
            }]))
            api.createNewChatThread(selectedContactId.senderId,selectedContactId.receiverId,{message: messageText})
            .then(res => {
                dispatch(Actions.setselectedContactId(res.data));
                dispatch(Actions.getFriendlist(users.uuid));
            })
        }else if (selectedContactId.chatId){
            const sendMessageRequest =
            {
              content: messageText
            };
            let sendMessageOptions =
            {
              senderDisplayName : '',
              type: 'text',
            };
            await newThread.sendMessage(sendMessageRequest, sendMessageOptions);
            RefreshClient();
            api.updateNewChatThread({
                id: selectedContactId.receiverId, 
                chatId: selectedContactId.chatId,
            })

        }
        setMessageText('');
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, messageText, newThread, selectedContactId.chatId, selectedContactId.new, selectedContactId.receiverId, selectedContactId.senderId, users.uuid]);


    return (
        <div className={clsx("flex flex-col relative", props.className)}>
            <FuseScrollbars
                ref={chatRef}
                className="flex flex-1 flex-col overflow-y-auto"
            >
                {listChats.length > 0 ?
                    (
                        <div className="flex flex-col pt-16 pl-56 pr-16 pb-40">
                            {listChats.map((item, i) => {
                                return (
                                    <div
                                        key={item.mId}
                                        className={clsx(
                                            classes.messageRow,
                                            "flex flex-col flex-grow-0 flex-shrink-0 items-start justify-end relative pr-16 pb-4 pl-16",
                                            { 'me': item.id === selectedContactId.senderTokenId || item.id === 'new'  },
                                            { 'contact': item.id !== selectedContactId.senderTokenId },
                                            { 'first-of-group': i === 0 },
                                            { 'last-of-group': (i + 1) === listChats.length },
                                            (i + 1) === listChats.length && "pb-96"
                                        )}
                                    >
                                        {shouldShowContactAvatar(item, i) && (
                                            <Avatar className="avatar absolute left-0 m-0 -ml-32" src={selectedContactId.avatar ||  "https://ui-avatars.com/api/?background=random&name="+selectedContactId.fullName} />
                                        )}
                                        <div className="bubble flex relative items-center justify-center p-12 max-w-full">
                                            <div className="leading-tight whitespace-pre-wrap">{item.message}</div>
                                            <Typography className="time absolute hidden w-full text-11 mt-8 -mb-24 left-0 bottom-0 whitespace-no-wrap"
                                                color="textSecondary">{moment(item.date).format("YYYY-MMM-DD h:mm:ss a")}</Typography>

                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 items-center justify-center">
                                <Icon className="text-128" color="disabled">chat</Icon>
                            </div>
                            <Typography className="px-16 pb-24 text-center" color="textSecondary">
                                Start a conversation by typing your message below.
                            </Typography>
                        </div>
                    )
                }

            </FuseScrollbars>
            {chat && (
                <form onSubmit={onMessageSubmit}  className="absolute bottom-0 right-0 left-0 py-16 px-8">
                    <Paper  className="flex items-center relative rounded-24" elevation={1}>
                        <TextField
                            autoFocus={false}
                            id="message-input"
                            className="flex-1"
                            disabled={selectedContactId.active === false}
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    root: "flex flex-grow flex-shrink-0 ml-16 mr-48 my-8",
                                    input: ""
                                },
                                placeholder: selectedContactId.active === false ? "You can't send messages to this chat" : "Type your message"
                            }}
                            InputLabelProps={{
                                shrink: false,
                                className: classes.bootstrapFormLabel
                            }}
                            onChange={onInputChange}
                            value={messageText}
                        />
                        <IconButton className="absolute right-0 top-0"  disabled={selectedContactId.active === false} type="submit">
                            <Icon className="text-24" color="action">send</Icon>
                        </IconButton>
                    </Paper>
                </form>
            )}
        </div>
    );
}

export default Chat;
