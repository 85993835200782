import React, { Component, useState } from "react";
import axios from "axios";
import * as authActions from "../../../auth/store/actions";
import { setUserData } from "../../../auth/store/actions/user.actions";
import { useDispatch } from "react-redux";
import { homePath } from "../../Data/Global";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import * as Actions from "../../../store/actions";
import api from "../../../services/httpService/api";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "radial-gradient(" +
      darken(theme.palette.primary.dark, 0.5) +
      " 0%, " +
      theme.palette.primary.dark +
      " 80%)",
    color: "#ffffff",
  },
}));

export default function SamlConsume() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("Validating...");
  const classes = useStyles();
  async function load() {
    const request = api.userAuthID();

    request.then((responseInfo) => {
      const response = responseInfo.data;
      if (response.path != "/") {
        setStatus("Logging In...");
        dispatch(setUserData(response));
      } else {
        setStatus("Not Active User...");
        window.location.href = response.path;
      }
    });
  }

  load();

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32"
      )}
    >
      <div className="flex flex-col items-center justify-center w-full">
        <Typography variant="h2" className="mt-16 font-bold">
          {status}
        </Typography>
      </div>
    </div>
  );
}
