import React from "react";

export const LocationsConfig = {
  settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: false
                }
            }
        }
  },
  routes: [
    {
      path: "/asset-management/locations/:productId",
      component: React.lazy(() => import("./LocationsRecord"))
    },
    {
      path: "/asset-management/locations",
      component: React.lazy(() => import("./Locations"))
    },

  ]
};
