import mock from './../mock';
import _ from '../../@lodash';

const scrumboardDB = {
    boards: [
        {
            'id'      : '32gfhaf2',
            'name'    : 'Sensor Dashboard',
            'uri'     : 'acme-frontend-application',
            'settings': {
                'color'          : 'fuse-dark',
                'subscribed'     : false,
                'cardCoverImages': false
            },
            'lists'   : [
                {
                    'id'     : '56027cf5a2ca3839a5d36103',
                    'name'   : 'Open',
                    'idCards': [
                        '2837273da9b93dd84243s0f9',
                        '2837273da9b93dd84243s0f8'
                    ]
                },
                {
                    'id'     : '56127cf2a2ca3539g7d36103',
                    'name'   : 'In Review',
                    'idCards': [
                    ]
                },
                {
                    'id'     : 'faf244627326f1249525763d',
                    'name'   : 'Upcoming Reviewed',
                    'idCards': [
                    ]
                },
                {
                    'id'     : 'ad7d.9fffac5dff412.c83bca6853767.8fd7549b2b1ca.ceda8a01774c4.a5cf3976e87e4.ce79eeeea',
                    'name'   : 'Closed',
                    'idCards': [
                    ]
                }
            ],
            'cards'   : [
                {
                    'id'               : '2837273da9b93dd84243s0f9',
                    'name'             : 'WO10 - Eletronics - Frac Unit {10081023} 53Q11467' ,
                    'description'      : 'Electronics - HMI{ Q001106} {Unplanned Task}' ,
                    'priority': 'High',
                    'responsiblePerson': 'Micheal Hall',
                    'maintenancePlant':'ZM06_Capical project/Refurb order',
                    'planningPlant': 'ZM06_4_Refurbishment material',
                    'workType': 'ZM06_4_Refurbishment material',
                    'maintenanceType':'ZM06_4_Refurbishment material',
                    'linkedProject':'',
                    'externalWorkOrderReference':'4000002744',
                    'createdBy':'Jane Doe',
                    'totalLaborCost':'500.00',
                    'totalPartsCost':'500.00',
                    'jobDetails':[
                        {
                        id: '2837273da9b93dd84243s',
                        operation: 'Power End - Pinion/Bearings {1325_02}',
                        estStartDate: new Date('02/16/2020'),
                        startDate: new Date(),
                        endDate: new Date('04/18/2020'),
                        labor: [{
                            name: 'James Smith',
                            code: 123,
                            totalHours: 57,
                            rate:55,
                            cost:3135.00,
                            details:[{
                                submissionDate: new Date('02/16/2020'),
                                hoursSubmitted: 7,
                               },
                               {
                                   submissionDate: new Date('02/18/2020'),
                                   hoursSubmitted: 10,
                               },
                           ]
                        },
                        {
                            name: 'Maria Smith',
                            totalHours: 27,
                            code:2033,
                            rate:55,
                            cost:1485.00,
                            details:[{
                                submissionDate: new Date('02/16/2020'),
                                hoursSubmitted: 7,
                               },
                               {
                                   submissionDate: new Date('02/18/2020'),
                                   hoursSubmitted: 10,
                               },
                               {
                                   submissionDate: new Date('02/25/2020'),
                                   hoursSubmitted: 7,
                               }
                           ]
                        },
                        {
                            name: 'Soul Smith',
                            totalHours: 25,
                            code:654,
                            rate:87,
                            cost:2175.00,
                            details:[{
                                submissionDate: new Date('02/16/2020'),
                                hoursSubmitted: 7,
                               },
                           ]
                        }

                       ],
                       parts: [{
                           name: 'HAMMER UNION ASSY 200 4',
                           code: '155',
                           unit: 'EA.',
                           unitCost:313.20,
                           estimatedQty:3,
                           issuedQty:3,
                           usedQty:3,
                           fromLocation:{name:'Warehouse 1',code:123},
                           status:'active'
                       },
                       {
                           name: 'RESISTOR 120 OHM',
                           code: '264',
                           unit: 'EA.',
                           unitCost:14.50,
                           estimatedQty:5,
                           issuedQty:5,
                           usedQty:3,
                           fromLocation:{name:'Warehouse 1',code:123},
                           status:'active'
                       },
                       {
                           name: 'GASKET FULL FACE 14',
                           code: '342',
                           unit: 'EA.',
                           unitCost:21.75,
                           estimatedQty:2,
                           issuedQty:2,
                           usedQty:2,
                           fromLocation:{name:'Warehouse 1',code:123},
                           status:'active'
                       }
                   ],
                   attachments:[ {
                       name: '2020 report.pdf',
                       type:'pdf',
                       size:29392
                   }]
                    },
                    {
                        id: '2837273da9b93dd84243s1',
                        operation: 'Power End - Frame {1325_04}',
                        estStartDate: new Date('02/16/2020'),
                        startDate: new Date(),
                        endDate: new Date('04/18/2020'),
                        labor: [
                       ],
                       parts: [
                   ],
                   attachments:[ {
                       name: '2020 report.pdf',
                       type:'pdf',
                       size:29392
                   }]
                    },
                    {
                        id: '2837273da9b93dd84243s2',
                        operation: 'Power End - Frame {1325_05}',
                        estStartDate: new Date('02/17/2020'),
                        startDate: new Date(),
                        endDate: new Date('06/22/2020'),
                        labor: [
                        ],
                        parts: [{
                            name: 'HAMMER UNION ASSY 200 4',
                            code: '155',
                            unit: 'EA.',
                            unitCost:313.20,
                            estimatedQty:3,
                            issuedQty:3,
                            usedQty:3,
                            fromLocation:'Warehouse 1',
                            status:'active'
                        },
                        {
                            name: 'RESISTOR 120 OHM',
                            code: '264',
                            unit: 'EA.',
                            unitCost:14.50,
                            estimatedQty:5,
                            issuedQty:5,
                            usedQty:3,
                            fromLocation:'Warehouse 1',
                            status:'active'
                        },
                        {
                            name: 'GASKET FULL FACE 14',
                            code: '342',
                            unit: 'EA.',
                            unitCost:21.75,
                            estimatedQty:2,
                            issuedQty:2,
                            usedQty:2,
                            fromLocation:'Warehouse 1',
                            status:'active'
                        }
                    ],
                    attachments:[ {
                        name: '2020 report.pdf',
                        type:'pdf',
                        size:29392
                    }]
                    }
                ],
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '26027s1930450d8bf7b10828'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36cd'
                    ],
                    'attachments'      : [],
                    'subscribed'       : false,
                    'checklists'       : [],
                    'activities'       : [
                        {
                            'id'      : 1,
                            'type'    : 'comment',
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'AngularCLI could be a nice alternative.',
                            'time'    : 'now'
                        }
                    ],
                    'due'              : new Date()
                },
                {
                    'id'               : '2837273da9b93dd84243s0f8',
                    'name'             : 'Testing Unit {10081023} 53Q11467' ,
                    'description'      : 'Electronics - HMI{ Q001106} {Unplanned Task}' ,
                    'priority': 'High',
                    'responsiblePerson': 'Micheal Hall',
                    'maintenancePlant':'ZM06_Capical project/Refurb order',
                    'planningPlant': 'ZM06_4_Refurbishment material',
                    'workType': 'ZM06_4_Refurbishment material',
                    'maintenanceType':'ZM06_4_Refurbishment material',
                    'linkedProject':'',
                    'externalWorkOrderReference':'4000002744',
                    'createdBy':'Jane Doe',
                    'totalLaborCost':'500.00',
                    'totalPartsCost':'500.00',
                    'jobDetails':[
                        {
                        id: '2837273da9b93dd84243s',
                        operation: 'Power End - Pinion/Bearings {1325_02}',
                        estStartDate: new Date('02/16/2020'),
                        startDate: new Date(),
                        endDate: new Date('04/18/2020'),
                        labor:[],
                        parts:[],
                        attachments:[]
                    },
                    {
                        id: '2837273da9b93dd84243s1',
                        operation: 'Power End - Frame {1325_04}',
                        estStartDate: new Date('02/16/2020'),
                        startDate: new Date(),
                        endDate: new Date('04/18/2020'),
                        labor:[],
                        parts:[],
                        attachments:[]
                    },
                    {
                        id: '2837273da9b93dd84243s2',
                        operation: 'Power End - Frame {1325_05}',
                        estStartDate: new Date('02/17/2020'),
                        startDate: new Date(),
                        endDate: new Date('06/22/2020'),
                        labor:[],
                        parts:[],
                        attachments:[]
                    }
                ],
                    'idAttachmentCover': '',
                    'idMembers'        : [
                        '26027s1930450d8bf7b10828'
                    ],
                    'idLabels'         : [
                        '26022e4129ad3a5sc28b36cd'
                    ],
                    'attachments'      : [],
                    'subscribed'       : false,
                    'checklists'       : [],
                    'activities'       : [
                        {
                            'id'      : 1,
                            'type'    : 'comment',
                            'idMember': '36027j1930450d8bf7b10158',
                            'message' : 'AngularCLI could be a nice alternative.',
                            'time'    : 'now'
                        }
                    ],
                    'due'              : new Date()
                }
            ],
            'members' : [
                {
                    'id'    : '56027c1930450d8bf7b10758',
                    'name'  : 'Alice Freeman',
                    'avatar': 'assets/images/avatars/alice.jpg'
                },
                {
                    'id'    : '26027s1930450d8bf7b10828',
                    'name'  : 'Danielle Obrien',
                    'avatar': 'assets/images/avatars/danielle.jpg'
                },
                {
                    'id'    : '76027g1930450d8bf7b10958',
                    'name'  : 'James Lewis',
                    'avatar': 'assets/images/avatars/james.jpg'
                },
                {
                    'id'    : '36027j1930450d8bf7b10158',
                    'name'  : 'John Doe',
                    'avatar': 'assets/images/avatars/Velazquez.jpg'
                }
            ],
            'labels'  : [
                {
                    'id'   : '26022e4129ad3a5sc28b36cd',
                    'name' : 'High Priority',
                    'class': 'bg-red text-white'
                },
                {
                    'id'   : '56027e4119ad3a5dc28b36cd',
                    'name' : 'Design',
                    'class': 'bg-orange text-white'
                },
                {
                    'id'   : '5640635e19ad3a5dc21416b2',
                    'name' : 'App',
                    'class': 'bg-blue text-white'
                },
                {
                    'id'   : '6540635g19ad3s5dc31412b2',
                    'name' : 'Feature',
                    'class': 'bg-green text-white'
                }
            ]
        }
    ]
};

let searchText = "";

mock.onGet('/api/scrumboard-app/boards').reply(() => {
    const response = _.map(scrumboardDB.boards, (board) => _.pick(board, ['id', 'name', 'uri']));
    return [200, response];
});

mock.onPost('/api/scrumboard-app/board/new').reply((request) => {
    const {board} = JSON.parse(request.data);
    scrumboardDB.boards = [...scrumboardDB.boards, board];
    return [200, _.pick(board, ['id', 'name', 'uri'])];
});

mock.onPost('/api/scrumboard-app/board/rename').reply((request) => {
    const {boardId, boardTitle} = JSON.parse(request.data);
    const board = _.find(scrumboardDB.boards, {id: boardId});
    _.set(board, 'name', boardTitle);

    return [200, boardTitle];
});

mock.onGet('/api/scrumboard-app/board').reply((config) => {
    const {boardId} = config.params;
    const response = _.find(scrumboardDB.boards, {id: boardId});
    if ( response )
    {
        return [200, response];
    }
    else
    {
        return [404, 'Requested board do not exist.'];
    }
});

mock.onPost('/api/scrumboard-app/board/settings/update').reply((request) => {
    const {boardId, settings} = JSON.parse(request.data);
    const board = _.find(scrumboardDB.boards, {id: boardId});
    _.set(board, 'settings', settings);

    return [200, settings];
});

mock.onPost('/api/scrumboard-app/board/delete').reply((request) => {
    const {boardId} = JSON.parse(request.data);
    scrumboardDB.boards = _.reject(scrumboardDB.boards, {id: boardId});
    return [200, true];
});

mock.onPost('/api/scrumboard-app/card/new').reply((request) => {
    const {boardId, listId, data} = JSON.parse(request.data);
    let board = _.find(scrumboardDB.boards, {id: boardId});

    _.assign(board,
        {
            cards: [...board.cards, data],
            lists: _.map(board.lists, _list => {
                if ( _list.id === listId )
                {
                    _.assign(_list, {idCards: [..._list.idCards, data.id]});
                }
                return _list
            })
        }
    );

    return [200, board];
});

mock.onPost('api/inventory-items/search-data').reply((search) => {
    searchText = search
    if (search !== null)
        return[200, searchText];
    else
        return [500, 'Unable to update search'];
});

mock.onGet('api/inventory-items/search-data').reply((search) => {
    const response = searchText;
    if (response) {
        return [200, response];
    }
    else {
        return [404, 'Unable to search right now.'];
    }
});

mock.onPost('/api/scrumboard-app/list/new').reply((request) => {
    const {boardId, data} = JSON.parse(request.data);
    const board = _.find(scrumboardDB.boards, {id: boardId});
    const lists = [...board.lists, data];

    _.assign(board, {lists});

    return [200, lists];
});

mock.onPost('/api/scrumboard-app/list/rename').reply((request) => {
    const {boardId, listId, listTitle} = JSON.parse(request.data);
    const board = _.find(scrumboardDB.boards, {id: boardId});
    const list = _.find(board.lists, {id: listId});
    _.assign(list, {name: listTitle});

    return [200, listTitle];
});

mock.onPost('/api/scrumboard-app/list/remove').reply((request) => {
    const {boardId, listId} = JSON.parse(request.data);
    const board = _.find(scrumboardDB.boards, {id: boardId});
    _.set(board, 'lists', _.reject(board.lists, {id: listId}));
    return [200, true];
});

mock.onPost('/api/scrumboard-app/card/update').reply((request) => {
    const {boardId, card} = JSON.parse(request.data);
    const board = _.find(scrumboardDB.boards, {id: boardId});
    const selectedCard = _.find(board.cards, {id: card.id});
    _.assign(selectedCard, card);
    // console.info('updated');
    return [200, card];
});

mock.onPost('/api/scrumboard-app/card/remove').reply((request) => {
    const {boardId, cardId} = JSON.parse(request.data);
    const board = _.find(scrumboardDB.boards, {id: boardId});
    _.assign(board, {
            cards: _.reject(board.cards, {id: cardId}),
            lists: board.lists.map(list => {
                _.set(list, 'idCards', _.reject(list.idCards, (id) => id === cardId));
                return list;
            })
        }
    );
    return [200, cardId];
});

mock.onPost('/api/scrumboard-app/card/order').reply((request) => {
    const {boardId, lists} = JSON.parse(request.data);
    const board = _.find(scrumboardDB.boards, {id: boardId});
    _.assign(board, {lists});
    return [200, lists];
});


mock.onPost('/api/scrumboard-app/list/order').reply((request) => {
    const {boardId, lists} = JSON.parse(request.data);
    const board = _.find(scrumboardDB.boards, {id: boardId});
    _.assign(board, {lists});
    return [200, lists];
});
