/*
const fuseDark = {
    50  : '#ECECEE',
    100 : '#C5C6CB',
    200 : '#9EA1A9',
    300 : '#7D818C',
    400 : '#5C616F',
    500 : '#3C4252',
    600 : '#353A48',
    700 : '#2D323E',
    800 : '#262933',
    900 : '#1E2129',
    A100: '#C5C6CB',
    A200: '#9EA1A9',
    A400: '#5C616F',
    A700: '#2D323E'
};
*/
const fuseDark = {
    50: '#ECECEE',
    100: '#C5C6CB',
    200: '#9EA1A9',
    300: '#f5f5f5', //Light grey '#5d0000'  //old: 670000
    400: '#5C616F',
    500: '#00305b',
    600: '#ff5900', //orange
    700: '#00305b',
    800: '#5d0000',//Red
    900: '#1E2129',
    A100: '#C5C6CB',
    A200: '#9EA1A9',
    A400: '#5C616F',
    A700: '#00305b',
    A800: '#670000'
};

export default fuseDark;
