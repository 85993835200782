import React, { useState, useEffect, Fragment } from "react";
import { Card, CardContent, Typography, Tabs, Tab } from "@material-ui/core";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { FuseAnimate } from "../../../@fuse";
import clsx from "clsx";
import JWTLoginTab from "./tabs/JWTLoginTab";
import { makeStyles } from "@material-ui/styles";
import Api from "../../services/httpService/api";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";
import SSOLogin from "./tabs/SSOLogin";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "linear-gradient(to right, " +
      theme.palette.primary.dark +
      " 0%, " +
      darken(theme.palette.primary.dark, 0.5) +
      " 100%)",
    color: theme.palette.primary.contrastText,
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    backgroundSize: "cover",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    marginTop: "20%",
    color: "#ffffff", //#00305B
    fontWeight: 300,
  },
}));

function Login() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(2);
  const dispatch = useDispatch();
  const tenant = useSelector(({ fuse }) => fuse.tenant);

  useEffect(() => {
    dispatch(Actions.fetchTenant());
    dispatch(Actions.fetchLogo());
    dispatch(Actions.fetchLoginBackground());
  }, []);

  function handleTabChange(event, value) {
    setSelectedTab(value);
  }

  return (
    <div
      className={clsx(
        classes.root,
        classes.quote,
        "flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0"
      )}
    >
      <div className="flex flex-col flex-grow-0 items-center text-white text-center md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
        <img src={tenant.loginBackground} className="h-full w-full" />
      </div>

      <FuseAnimate animation={{ translateX: [0, "100%"] }}>
        <Card className="w-full h-full max-w-400 mx-auto m-16 md:m-0" square>
          <CardContent className="h-full">
            <div className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
              {tenant.logo !== null && (
                <img
                  src={tenant.logo}
                  alt="Main Logo"
                  style={{ height: "75px" }}
                />
              )}
              <Typography variant="h6" className="text-center md:w-full mb-48">
                LOGIN TO YOUR ACCOUNT
              </Typography>

              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="fullWidth"
                className="w-full mb-32"
              >
                <Tab
                  icon={
                    <img
                      className="h-40 p-4"
                      src="assets/images/logos/EAMS_Tech_Logo_Shaded.svg"
                      alt=""
                    />
                  }
                  className="min-w-0"
                  label="Sign In"
                  value={1}
                />
              </Tabs>

              {tenant.tenantDetails.hasSSO !== undefined && (
                <Fragment>
                  {tenant.tenantDetails.hasSSO !== true ? (
                    <JWTLoginTab />
                  ) : (
                    <SSOLogin />
                  )}
                </Fragment>
              )}
            </div>
            <div
              style={{ position: "absolute", bottom: 0, padding: 5 }}
              className="w-full flex flex-row justify-between"
            >
              <div>
                <Typography>© EAMS Technologies 2024</Typography>
              </div>
              <div>
                <a
                  href="https://www.iox-connect.com/software-eula"
                  target="_blank"
                  variant="body2"
                >
                  EULA
                </a>
              </div>
              <div>
                <a
                  href="https://www.iox-connect.com/terms-of-service"
                  target="_blank"
                  variant="body2"
                >
                  Terms
                </a>
              </div>
              <div style={{ width: 30 }}></div>
            </div>
          </CardContent>
        </Card>
      </FuseAnimate>
    </div>
  );
}

export default Login;
