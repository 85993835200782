import React from "react";

export const ClassificationsConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
  routes: [
    {
          path: "/settings/classification-schemes",
      component: React.lazy(() => import("./Classifications"))
    }
  ]
};
