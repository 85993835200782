import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Typography,
  InputAdornment,
  Icon,
  TextField,
  CircularProgress
} from "@material-ui/core";
import { TextFieldFormsy } from "../../../../@fuse";
import Formsy from "formsy-react";
import * as authActions from "../../../../app/auth/store/actions";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

function JWTLoginTab(props) {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (login.error && (login.error.error.email || login.error.error.password)) {
      formRef.current.updateInputsWithError({
        ...login.error.error
      });
      disableButton();
    }
  }, [login.error]);

  function disableButton() {
    setIsFormValid(false);
  }

  function enableButton() {
    if (!login.error.locked) {
      setIsFormValid(true);
    }
  }

  function handleSubmit(model) {
    setLoading(true);
    dispatch(authActions.submitLogin(model)).finally(() => {
      setLoading(false);
    });
  }

  return (
    <div className="w-full">
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="flex flex-col justify-center w-full"
      >
        <TextFieldFormsy
          className="mb-16"
          type="text"
          name="email"
          label="Username/Email"
          validations={{
            minLength: 4,
          }}
          validationErrors={{
            minLength: "Min character length is 4",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20" color="action">
                  email
                </Icon>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          required
        />

        <TextFieldFormsy
          className="mb-16"
          type="password"
          name="password"
          label="Password"
          validations={{
            minLength: 4,
          }}
          validationErrors={{
            minLength: "Min character length is 4",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20" color="action">
                  vpn_key
                </Icon>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          required
        />

        {!login.error.locked && login.error.retries < 3 && login.error.retries > -1 && (
            <div className="error-message">
                <TextField
                    className="error-message"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    multiline
                    value={`Login attempt unsuccessful. You have ${3 - login.error.retries} attempt(s) left.`}
                    InputProps={{
                        style: { color: "red", fontWeight: "bold", overflow: "hidden" },
                    }}
                    disabled
                />
            </div>
        )}

        {login.error.locked && (
            <div className="error-message">
                <TextField
                    className="error-message"
                    name="errorMessage"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    multiline
                    value="Your account has been locked due to too many failed login attempts."
                    InputProps={{
                        style: { color: "red", fontWeight: "bold", overflow: "hidden" },
                    }}
                    disabled
                />
            </div>
        )}

        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
          </div>
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="w-full mx-auto mt-16 normal-case"
            aria-label="LOG IN"
            disabled={!isFormValid}
            value="legacy"
          >
            Login
          </Button>
        )}
      </Formsy>
    </div>
  );
}

export default JWTLoginTab;
