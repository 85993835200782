import * as Actions from '../../actions/fuse/index';
import navigationConfig from '../../../fuse-configs/navigationConfig';

const initialState = {
    list: [],
    page: {
        create: false,
        update: false
    }
};

const navigation = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_NAVIGATION:
        {
                return {
                    ...state
                };
        }
        case Actions.SET_NAVIGATION:
        {
            return {
                    ...state,
                    list: action.navigation
            };
        }
        case Actions.RESET_NAVIGATION:
        {
            return {
                ...initialState
            };
            }
        case Actions.FILTER_NAVIGATION:
            {
                function isVisible(id, item) {
                    if (id === undefined) {
                        const parent = action.navigation.map(function (v) { return v.moduleId; });
                        return parent.includes(item.id)
                    }
                    else {
                        let child;
                        action.navigation.filter(c => c.moduleId === id).map(function (v)
                        {
                            child = v.subModules.map(function (v) { return v.subModuleId; });
                        });
                        return child.includes(item.id)
                    }
                }
                let filteredNavigation = navigationConfig.filter(item => isVisible(undefined, item)).map(item => ({
                    ...item,
                    children: item.children
                        .filter(child => isVisible(item.id, child)).map((child) => {
                            const module = action.navigation.find(c => c.moduleId === item.id);
                            if (module) {
                                const perms = module.subModules.find(c => c.subModuleId === child.id);
                                if (perms) {
                                    child.create = perms.create;
                                    child.update = perms.update;
                                    child.limit = perms.limit;
                                }
                            }
                            
                            return child;
                        })
                }));

                return {
                    ...state,
                    list: filteredNavigation
                };
            }
        case Actions.GET_NAVITEM:
            {
                let pageDetail = {};
                const module = state.list.find(c => c.id === action.module);
                if (module) {
                    const perms = module.children.find(c => c.id === action.submodule);
                    if (perms) {
                        pageDetail = perms;
                    }
                    else {
                        pageDetail = initialState.page;
                    }
                }

                return {
                    ...state,
                    page: pageDetail
                };
            }
        default:
        {
            return state;
        }
    }
};

export default navigation;
