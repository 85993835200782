import React from 'react';
import { Icon, IconButton, Menu, MenuItem } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useDispatch } from 'react-redux';
import MarketingIcon from '../Icons/icons8-commercial-96.png';


function MarketingButton() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                className="w-64 h-64"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick} >
                <img
                    className="w-24"
                    src={MarketingIcon}
                    alt="Marketing"
                />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Pricing</MenuItem>
                <MenuItem onClick={handleClose}>Book Personalized Demo</MenuItem>
                <MenuItem onClick={handleClose}>Contact Sales</MenuItem>
            </Menu>
        </div>
        
    );
}

export default MarketingButton;
