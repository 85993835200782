import React from "react";
import { Icon, IconButton } from "@material-ui/core";
import _ from "../../../@lodash";
import * as Actions from "../../../app/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { darken } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import { dark } from "@material-ui/core/styles/createPalette";

const useStyles = makeStyles((theme) => ({
    navButton: {
        marginRight: 15,
        borderStyle: 'solid',
        borderColor: '#ffffff',
        borderWidth: 'thin',
        borderRadius: '10px',
        //background: 'radial-gradient(' + darken(theme.palette.primary.light, 0.5) + ' 0%, ' + theme.palette.primary.dark + ' 80%)',
        backgroundColor: theme.palette.primary[800],
        color: theme.palette.primary.contrastText,
        '&:hover': {
            background: theme.palette.primary[600],
            borderColor: theme.palette.primary.light
        },
    }
}));


function NavbarFoldedToggleButton(props) {
    const dispatch = useDispatch();
    const settings = useSelector(({ fuse }) => fuse.settings.current);
    const classes = useStyles();

    return (
        <IconButton
            className={props.className}
            onClick={() => {
                dispatch(
                    Actions.setDefaultSettings(
                        _.set(
                            {},
                            "layout.config.navbar.folded",
                            !settings.layout.config.navbar.folded
                        )
                    )
                );
            }}
            color="inherit"
            classes={{
                root: classes.navButton
            }}

        >
            {props.children}
        </IconButton>
    );
}

NavbarFoldedToggleButton.defaultProps = {
    children: <Icon>menu</Icon>
};

export default NavbarFoldedToggleButton;
